import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Store} from "@ngxs/store";
import {SettingsState} from "../_stores/settings/_state/settings.state";

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {

  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.select(SettingsState.selectSettings).subscribe({
      next: settings => {
        if(request.url.includes('/api/')) {
          request = request.clone({
            setHeaders: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
            }
          });
        }
      }
    });

    return next.handle(request);
  }
}
