export class ChangeLanguageAction  {
  public static readonly type = '[System] ChangeLanguageAction';
  constructor(public language: string) {}
}
export class ChangeSoundAction  {
  public static readonly type = '[System] ChangeSoundAction';
  constructor() {}
}
export class SetSoundAction  {
  public static readonly type = '[System] SetSoundAction';
  constructor(public sound: boolean) {}
}

export class DisallowComingSoonAction  {
  public static readonly type = '[System] DisallowComingSoonAction';
  constructor() {}
}

export class ChangeMaxAction  {
  public static readonly type = '[System] ChangeMaxAction';
  constructor(public max: { energy: number, life: number }) {}
}
