import {SignalItemModel} from "../_models/signals.model";


export class InitSSESignals  {
  public static readonly type = '[Signals] InitSSESignals';
  constructor() {}
}
export class InitSSETimelines  {
  public static readonly type = '[Signals] InitSSETimelines';
  constructor() {}
}



export class UpdateActualSignals  {
  public static readonly type = '[Signals] UpdateActualSignals';
  constructor(public signals: SignalItemModel[]) {}
}

export class UpdateActualTimelines  {
  public static readonly type = '[Signals] UpdateActualTimelines';
  constructor(public timelines: any[]) {}
}


export class LoadSignalsFromJson  {
  public static readonly type = '[Signals] LoadSignalsFromJson';
  constructor() {}
}
export class LoadSignalsFromApi  {
  public static readonly type = '[Signals] LoadSignalsFromApi';
  constructor() {}
}
