export class ModalOpenAction  {
  public static readonly type = '[Modal] ModalOpenAction';
  constructor(public data: string, public close?: boolean) {}
}
export class ModalOpenActionWithID  {
  public static readonly type = '[Modal] ModalOpenActionWithID';
  constructor(public data: string, public _id?: string, public close?: boolean) {}
}
export class ModalCloseAction  {
  public static readonly type = '[Modal] ModalCloseAction';
  constructor() {}
}
