import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UniversalInterceptor} from './_interceptors/universal.interceptor';

@NgModule({
  imports: [CommonModule],
  providers: [UniversalInterceptor],
  declarations: []
})
export class AppSharedModule {}
