import {HttpClient} from '@angular/common/http';
import {provideTransloco, Translation, TranslocoLoader, TranslocoModule} from '@ngneat/transloco';
import {inject, Inject, Injectable, NgModule, PLATFORM_ID} from '@angular/core';
import {environment} from '../environments/environment';
import {isPlatformBrowser} from "@angular/common";

/*
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private url: string;
  private platform: any;

  constructor( @Inject(PLATFORM_ID) platformId: Object) {
    this.url = isPlatformBrowser(platformId)? '': environment.baseUrl;
    this.platform = platformId;
  }
  getTranslation(lang: string): Observable<Object> {
    console.log("LANG:", lang)
    if(lang == 'ua') {
      return new Observable<Translation>((subscriber) => {
        subscriber.next(DEFAULT_LANG_FILE);
        subscriber.complete();
      });
    } else {
      //return this.http.get<Translation>(API.http('/assets/i18n/'+lang+'.json'));
      return this.http.get<Translation>(`${this.url}/assets/i18n/${lang}.json`, {withCredentials: true});
    }


  }
}
*/
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private url: string;
  private platform: any;

  constructor( @Inject(PLATFORM_ID) platformId: Object) {
    this.url = isPlatformBrowser(platformId)? '': environment.baseUrl;
    this.platform = platformId;
  }

  getTranslation(lang: string) {
    let _request_url = `${environment.translocoUrl}/assets/i18n/${lang}.json`;
    //console.log(isPlatformBrowser(this.platform), _request_url);
    if(!isPlatformBrowser(this.platform)) {
      _request_url = `${environment.baseUrl}/assets/i18n/${lang}.json`;
    }
    return this.http.get<Translation>(_request_url);
  }
}


@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'ua', 'ru', 'es', 'tr'],
        defaultLang: environment.default_language,
        fallbackLang: environment.default_language,
        reRenderOnLangChange: true,
        missingHandler: {
          useFallbackTranslation: false
        },
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    })
  ]
})
export class AppTranslocoRootModule {}
