import {AtuItemModel} from "../_models/atu.model";
import {SignalItemModel} from "../../signals/_models/signals.model";

export class UpdateAtu  {
  public static readonly type = '[Atu] UpdateAtu';
  constructor(public atu: AtuItemModel[]) {}
}

export class LoadAtuFromJson  {
  public static readonly type = '[Atu] LoadAtuFromJson';
  constructor() {}
}

export class LoadAtuActualGeo  {
  public static readonly type = '[Atu] LoadAtuActualGeo';
  constructor(public signals: SignalItemModel[]) {}
}
