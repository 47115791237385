export class TipsOpenAction  {
  public static readonly type = '[Tips] TipsOpenAction';
  constructor(
    public options: {
      staff:string,
      visual: string,
      title?:string,
      text?:string,
      buttons?: string | string[],
      type?:string,
    }) {}
}
export class TipsCloseAction  {
  public static readonly type = '[Tips] TipsCloseAction';
  constructor() {}
}
